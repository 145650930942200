import axios from 'axios';

export default class {
    constructor (options = {}) {
        this.endpoint = 'categorias'
    }

    get(){
        let uri = process.env.VUE_APP_API_BASE_URI + this.endpoint

        return axios({
            url: uri,
            method: 'get',
            timeout: this.timeout
        })
    }    
}