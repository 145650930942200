<template>
  <v-app>
    <NavComponent 
      ref="navDrawer" 
      :layout="isMobile?'bottom':'drawer'" 
      :navDrawerWidth="navDrawerWidth" 
      @input="categoryPicker" 
      @load-completed="mapLoad" 
      @append-category-leaf="appendCategoryLeaf" 
      :isMobile="isMobile"
      @show-snackbar="showDialog"/>

    <ResultComponent 
      ref="resultDrawer" 
      :layout="isMobile?'bottom':'drawer'" 
      :isMobile="isMobile" 
      @reload-app="$emit('reload-app')" 
      @show-snackbar="showSnackbar"
      @map:center="mapSetCenter"
      />

    <v-main>
      <leaflet-map ref="leafletMap" 
        :zoomControl="false"
        :attributionControl="!isMobile"
        :mobile="isMobile"
        @toggle-sidebar="$refs.navDrawer.show = !$refs.navDrawer.show"
        @error:get-current-location="onErrorGetCurrentLocation"
        @update:bounds="onUpdateBounds"
        />
    </v-main>

    <Dialog ref="dialog"/>

    <!-- SNACK BAR to show alert messages -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      ref="snackbar"
    >
      {{snackbar.text}}

      <template v-slot:action="{ attrs }" v-if="snackbar.closeOpt">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- SNACK BAR to show New Version of mapasolidario.org.ar -->
    <v-snackbar
      v-model="snackbarNewVersionAlert"
      color="#0066A7"
      dark
      :timeout="-1"
    >
      Una Nueva versión del Mapa está disponible!
      <v-btn
        color="blue"
        text
        dark
        @click.stop="$emit('reload-app')"
      >
        Recargar
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import NavComponent from './partials/NavComponent.vue';
import ResultComponent from './partials/ResultComponent.vue';
import Dialog from './partials/Dialog.vue';
import LeafletMap from './map/LeafletMap.vue';
import institutionsService from '../services/institutions.service.js';
import mapUrlHelper from '../helpers/mapurl.helper.js';
 
export default {
  components: {
    LeafletMap,
    NavComponent,
    ResultComponent,
    Dialog
  },
  props: {
    isMobile: Boolean,
    navDrawerWidth: Number,
    param1: String,
    param2: String,
    param3: String
  },
  metaInfo: {
    //
  },
  mounted() {
    this.onRouteChange(null,null)
  },
  computed: {
    
  },
  created() {
    this.mapUrlHelperInstance = new mapUrlHelper()
  },
  watch: {
      $route(to,from) {
        this.onRouteChange(to,from)
      }
  },
  methods: {
      map(){
        return this.$refs.leafletMap
      },
      onUpdateBounds(){

        if(this.map().getCentroidToString()!=this.$route.params.centroid){

          this.$route.params.centroid = this.map().getCentroidToString()
          this.$router.push({ name: this.$router.currentRoute.name, params: this.$router.currentRoute.params})
          this.mapLoad(false) //cargo más markers

         }

      },
      mapSetCenter(centroid,zoom){
        let latLng = {
          lat: centroid.lat,
          lng: centroid.long
        }


        this.map().setView(latLng,zoom)
      },
      mapLoad(isInitailLoad=false){

        //return if it is already loading
        if(this.loading) return

        if(Object.keys(this.LCategories).length==0){
          console.error("Las categorías están vacías, por favor recargar.")
          return
        }

        let zoomLevel = this.map().getZoom()
        let center = this.map().getCenter()

        //if zoom level is less than 13, don't load institutions
        if(zoomLevel<13){
          return
        }

        this.loading = true
        this.initialLoad = isInitailLoad

        this.services.institutions.get(center.lat,center.lng, 'categorias').then(response => {
            this.onInstitutionsResponse(response)
        }).catch((e) => {
            //TODO add friendly error
            console.error("Institutions service fail:",e)
        })
      },
      onRouteChange(to,from){

        //just to avoid double calls
        if(from && to){
          if(from === to) {
            return
          }
        }
        
        //if a centroid comes from url string, move the map to that centroid
        if(this.$route.name==='map-wrapper' && this.$route.params.centroid){
          let coords = this.map().stringToCentroid(this.$route.params.centroid)
          this.map().setView(coords,coords.z)
        }

        //map-action checks action parameter to execute
        if(this.$route.name==='map-action'){

          let action = this.mapUrlHelperInstance.getAction(this.$route)
          
          switch (action) {
            case 'institucion':
              
              if(from && to){
                if(from === to) {
                  return
                }
              }

              let urlData = this.mapUrlHelperInstance.getUrlData(this.$route)

              //if there is not centroid, then redirect with centroid
              if(!urlData.centroid){
                urlData.centroid = this.mapUrlHelperInstance.stringifyCentroid(this.map().getCenter(),this.map().getZoom())
                this.$router.push({ name: 'map-action', params: this.mapUrlHelperInstance.urlParamsBuilder(urlData)})
              }
              
              //TODO add query parameter value into search input (when search exists)
              let institutionId = urlData.data.id

              //TODO helper for URL string
              this.$refs.resultDrawer.showInstitution(institutionId,this.LCategories)

              //first load, then open result drawer: 
              if(from === null && to === null) this.$refs.resultDrawer.open()


              break;
          
            /*TODO default:
              this.$router.push({ name: 'map-wrapper', params: {centroid: this.mapGetCentroidAsString()}})
              break;*/
          }

        }
      },
      onInstitutionsResponse(response){

        //TODO: use a log utility
        //console.log("MapWrapper:onInstitutionsResponse:response",response)

        let instuticiones = response.data.value

        Object.values(instuticiones).forEach(instuticion => {

          this.instuticionesJson[instuticion.id] = instuticion

          Object.values(instuticion.categorias).forEach(category => {

            if(this.LCategories[category.id]){

              let myIcon = L.icon({
                  iconUrl: '/img/markers/marker-'+this.LCategories[category.id].data.clase.nombre+'.png',
                  iconSize: [17, 23],
                  iconAnchor: [8, 5],
              });

              let newMarker = new L.marker([instuticion.ubicacion.lat, instuticion.ubicacion.long], 
                          {icon: myIcon, rawData: instuticion}).on('click', this.markerOnClick).bindPopup('<b>'+instuticion.nombre+'</b>');;
              this.LCategories[category.id].featureGroup.addLayer(newMarker)
            }

          })
        })
        
        this.loading = false
      },
      markerOnClick(m){

        //TODO Move this to mapurlhelper
        let params = {
          action: 'institucion',
          query: m.sourceTarget.options.rawData.nombre.replace(/\s/g,'+'),
          centroid: this.mapUrlHelperInstance.stringifyCentroid(m.latlng,this.map().getZoom()),
          data: {
            id: m.sourceTarget.options.rawData.id,
            pos: this.mapUrlHelperInstance.stringifyCentroid(m.latlng,this.map().getZoom(),false)
          }
          //data:'data=id::'+m.sourceTarget.options.rawData.id+'!!pos::'+this.map().getCentroidToString(m.latlng,false)
        }
        //validation to avoid redundant navegation (when a marker is clicked twice)
        if(this.$router.currentRoute.params.data != params.data) this.$router.push({ name: 'map-action', params: this.mapUrlHelperInstance.urlParamsBuilder(params)})
        this.$refs.resultDrawer.open()
        
      },
      appendCategoryLeaf(category,visible=false){

        this.LCategories[category.id] = category

        if(visible){
          this.LCategories[category.id].featureGroup = new L.featureGroup([]).addTo(this.map().getMapObj())
          this.LCategories[category.id].visible = true
        }else{
          this.LCategories[category.id].featureGroup = new L.featureGroup([])
          this.LCategories[category.id].visible = false
        }
      },
      clearCategories(){
        Object.values(this.LCategories).forEach(category => {
          this.LCategories[category.id].featureGroup.clearLayers()
        })
      },
      categoryPicker(iParent,iChild,show){
          if(show){
            this.LCategories[iChild].featureGroup.addTo(this.map().getMapObj())
            this.LCategories[iChild].visible = true
          }else{
            this.LCategories[iChild].featureGroup.remove()
            this.LCategories[iChild].visible = false
          }
      },
      onErrorGetCurrentLocation(err){
        this.$logger.error(err.message);
        this.$refs.dialog.title = "👎 Se ha producido un error."
        this.$refs.dialog.msg = "No se ha podido determinar la ubicación. Intente en otro momento."
        this.$refs.dialog.show = true
      },
      showSnackbar(message,color){
        this.snackbar.text = message
        this.snackbar.color = color
        this.snackbar.show = true
        this.snackbar.closeOpt = true
      },
      showDialog(message){
        this.$refs.dialog.title = "👎"
        this.$refs.dialog.msg = message
        this.$refs.dialog.show = true
      }
  },
  data () {
    return {
      name: "MapWrapper",
      drawer: false,
      resultDrawer: true,
      loading: false,
      initialLoad: true,
      snackbarNewVersionAlert: false,
      snackbar:{
        show: false,
        text: "Textito",
        color: "success",
        closeOpt: true
      },
      services:{
        institutions: new institutionsService(),
      },
      mapUrlHelperInstance: null,
      //navCategories: null,
      LCategories: {},
      instuticionesJson: {}
    };
  }
}
</script>

<style>@import "~leaflet/dist/leaflet.css";</style>
<style>@import '~leaflet-minimap/dist/Control.MiniMap.min.css';</style>
<style src="../css/main.css"></style>

