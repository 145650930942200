<template>
    <v-dialog
      v-model="show"
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          {{((title.length==0)?"Información importante":title)}}
        </v-card-title>
        <v-card-text>
          <div class="text-h6">{{msg}}</div>
        </v-card-text>

        <!--v-divider></v-divider-->

        <!--v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions-->
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  mounted() {
    
  },
  computed: {
    //
  },
  methods: {
    
  },
  data () {
    return {
      show:false,
      msg:'',
      title:''
    };
  }
}
</script>
