import axios from 'axios';

export default class {
    constructor (options = {}) {
        this.endpoint = 'forms'
    }

    postContactForm(data){
        let uri = process.env.VUE_APP_API_BASE_URI + this.endpoint + '/contact'

        return axios({
            url: uri,
            method: 'post',
            timeout: this.timeout,
            data:data,
        })
    }   

    postIncident(data){
        let uri = process.env.VUE_APP_API_BASE_URI + this.endpoint + '/incident'

        return axios({
            url: uri,
            method: 'post',
            timeout: this.timeout,
            data:data,
        })
    }  
}