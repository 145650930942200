<template>
  <div>
    <v-bottom-sheet v-model="show" scrollable v-if="layout==='bottom'" hide-overlay persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="white"
          v-bind="attrs"
          v-on="on"
          fab
          bottom
          fixed
          x-small
          :style="{left: '50%', transform:'translateX(-50%)'}"
        >
          <v-icon>mdi-menu-up</v-icon>
        </v-btn>
      </template>
      <v-card>
          <v-card-text style="height: 50vh;">
            <div class="text-center" style="width: 100%;">
              <v-btn
                    icon
                    @click.stop="show = !show"
                    :loading="loading"
              >
                <v-icon>{{ !show?'mdi-menu-up':'mdi-menu-down'}}</v-icon>
              </v-btn>
            </div>
            
            <v-card
                class="mx-auto mt-2 py-4"
                outlined
                v-if="error"
              >
              <v-card-title>
                <v-icon color="red">mdi-close-octagon</v-icon> Se ha producido un error. 
              </v-card-title>

              <v-card-subtitle>{{error}}</v-card-subtitle>

              <v-card-actions>
                <v-btn text @click.stop="reloadPage">
                  recargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
            <v-card
                class="mx-auto mt-2 py-4"
                outlined
                v-if="!error"
              >
              <v-row class="mb-2"
                no-gutters
              >
                <v-col
                  v-for="(item) in this.items" 
                  :key="item.id"
                  cols="4"
                  class="my-0 py-0"
                >
                  <v-card height="100px" flat :ripple="false" link class='pt-2 text-center' :ref="item.id" @click.stop="parentOnClick(item.id)">
                    <div></div>
                    <v-badge
                      bordered
                      bottom
                      color="#064A60"
                      :icon="(items[item.id].selected==Object.keys(items[item.id].categorias).length?'mdi-check':'mdi-minus')"
                      offset-x="10"
                      offset-y="10"
                      :value="items[item.id].selected"
                    >     
                      <v-avatar
                        :color="item.data.clase.color"
                        dark
                        size="40" :title="false"
                      >
                        <v-icon dark>
                          {{icons[item.data.clase.nombre]}}
                        </v-icon>
                      
                      </v-avatar>
                    </v-badge>
                    <div class="text-caption my-3" style="word-break: normal;" >{{item.nombre}}</div>
                  </v-card>
                </v-col>
              </v-row>
              <v-expansion-panels flat v-if="Object.keys(items).length>0">
                <v-expansion-panel>
                <v-expansion-panel-header class="pl-2" style="height:10px"><v-spacer></v-spacer>más</v-expansion-panel-header>
                <v-expansion-panel-content class="pb-5 px-2">
                  <v-row
                  no-gutters
                  >
                    <template v-for="(val) in items">
                    <v-col
                      v-for="(v) in val.categorias" 
                      :key="v.id"
                      cols="3"
                      class="my-0 py-0"
                    >
                      <v-card height="100px" flat :ripple="false" link class='pt-2 text-center' :ref="v.id" @click.stop="childOnClick(val.id,v.id)">
                        <div></div>
                          <v-badge
                            bordered
                            bottom
                            color="#064A60"
                            icon="mdi-check"
                            offset-x="10"
                            offset-y="10"
                            :value="items[val.id].categorias[v.id].selected"
                          >    
                            <v-avatar
                              :color="v.data.clase.color"
                              dark
                              size="40" :title="false"
                            >
                              <v-icon dark>
                                {{icons[v.data.clase.nombre]}}
                              </v-icon>
                            
                            </v-avatar>
                          </v-badge>
                        <div class="text-caption my-3 text-truncate">{{v.nombre}}</div>
                      </v-card>
                    </v-col>
                    </template>
                  </v-row>
                </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          
            <v-card
                class="mx-auto mt-2"
                outlined
              >
              <v-list
                nav
              >
                <v-list-item
                  v-for="item in menu"
                  :key="item.title"
                  link
                  :to="item.to"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-navigation-drawer
        v-model="show"
        app
        :width="navDrawerWidth"
        temporary
        hide-overlay
        :permanent="show"
        style="z-index: 10;"
        color="#EDEDED"
        class="light"
         v-if="layout==='drawer'"
    >
        <v-list class="navdrawer-title" dense>
            <v-list-item class="px-2 my-0" dense>
              <v-list-item-avatar class="my-0">
                <v-img :src="require('@/assets/manos.png')"></v-img>
            </v-list-item-avatar>
              <v-list-item-title style="color:#064A60;" :class="(isMobile?'text-h8':'text-h6')">MAPA <b>SOLIDARIO</b></v-list-item-title>

              <v-btn
                icon
                @click.stop="show = !show"
                :loading="loading"
              >
                <v-icon v-if="!isMobile">{{ !show?'mdi-chevron-right':'mdi-chevron-left'}}</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-btn>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card
            class="mx-auto mt-2 py-4"
            outlined
            v-if="error"
          >
          <v-card-title>
            <v-icon color="red">mdi-close-octagon</v-icon> Se ha producido un error. 
          </v-card-title>

          <v-card-subtitle>{{error}}</v-card-subtitle>

          <v-card-actions>
            <v-btn text @click.stop="reloadPage">
              recargar
            </v-btn>
          </v-card-actions>
        </v-card>
      
        <v-card
            class="mx-auto mt-2 py-4"
            outlined
            v-if="!error"
          >
          <v-row class="mb-2"
            no-gutters
          >
            <v-col
              v-for="(item) in this.items" 
              :key="item.id"
              cols="4"
              class="my-0 py-0"
            >
              <v-card height="100px" flat :ripple="false" link class='pt-2 text-center' :ref="item.id" @click.stop="parentOnClick(item.id)">
                <div></div>
                <v-badge
                  bordered
                  bottom
                  color="#064A60"
                  :icon="(items[item.id].selected==Object.keys(items[item.id].categorias).length?'mdi-check':'mdi-minus')"
                  offset-x="10"
                  offset-y="10"
                  :value="items[item.id].selected"
                >     
                  <v-avatar
                    :color="item.data.clase.color"
                    dark
                    size="40" :title="false"
                  >
                    <v-icon dark>
                      {{icons[item.data.clase.nombre]}}
                    </v-icon>
                  
                  </v-avatar>
                </v-badge>
                <div class="text-caption my-3" style="word-break: normal;" >{{item.nombre}}</div>
              </v-card>
            </v-col>
          </v-row>
          <v-expansion-panels flat v-if="Object.keys(items).length>0">
            <v-expansion-panel>
            <v-expansion-panel-header class="pl-2" style="height:10px"><v-spacer></v-spacer>más</v-expansion-panel-header>
            <v-expansion-panel-content class="pb-5 px-2">
              <v-row
              no-gutters
              >
                <template v-for="(val) in items">
                <v-col
                  v-for="(v) in val.categorias" 
                  :key="v.id"
                  cols="3"
                  class="my-0 py-0"
                >
                  <v-card height="100px" flat :ripple="false" link class='pt-2 text-center' :ref="v.id" @click.stop="childOnClick(val.id,v.id)">
                    <div></div>
                      <v-badge
                        bordered
                        bottom
                        color="#064A60"
                        icon="mdi-check"
                        offset-x="10"
                        offset-y="10"
                        :value="items[val.id].categorias[v.id].selected"
                      >    
                        <v-avatar
                          :color="v.data.clase.color"
                          dark
                          size="40" :title="false"
                        >
                          <v-icon dark>
                            {{icons[v.data.clase.nombre]}}
                          </v-icon>
                        
                        </v-avatar>
                      </v-badge>
                    <div class="text-caption my-3 text-truncate">{{v.nombre}}</div>
                  </v-card>
                </v-col>
                </template>
              </v-row>
            </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
       
        <v-card
            class="mx-auto mt-2"
            outlined
          >
          <v-list
            nav
          >
            <v-list-item
              v-for="item in menu"
              :key="item.title"
              link
              :to="item.to"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import categoriesService from '../../services/categories.service.js';

export default {
  components: {
  },
  props: {
    isMobile: Boolean,
    navDrawerWidth: Number,
    layout: {
      type: String,
      default: 'drawer'
    }
  },
  mounted() {
    this.load(true)
  },
  computed: {
    //
  },
  methods: {
    reloadPage(){
      window.location.reload()
    },
    load(init=false) {
      
        this.loading = true
        this.initialLoad = init

        if(!this._categoriesSrv) this._categoriesSrv = this._initCategoriesSrv()

        this._categoriesSrv.get().then(response => {
            this.onResponse(response)
        }).catch((e) => {
            console.error("GetCategories",e)
            this.error = "Por favor, intentá refrescando el sitio!"
            this.loading = false
        })
    },
    _initCategoriesSrv(){
      return new categoriesService({})
    },
    onResponse(response){
      let categorias = response.data.value

      let result = {}

      for(let i in categorias){

        //si no tiene hijos no lo incluyo por el momento:
        if(categorias[i].categorias.length>0){

          let jsonTemplate = JSON.parse(JSON.stringify(categorias[i]))
          
          jsonTemplate.categorias = this.appendChildren(categorias[i].categorias)
          
          jsonTemplate.selected = Object.keys(categorias[i].categorias).length

          result[categorias[i].id] = jsonTemplate
        }
      }

      this.items=result
      this.$emit('load-completed', true, this.items);
      this.loading = false
    },
    appendChildren(children){

      let result = {}

      for(let i in children){
        
        let jsonTemplate = null
        if(children[i].categorias.length>0){

          jsonTemplate = JSON.parse(JSON.stringify(children[i]))
          
          jsonTemplate.categorias = this.appendChildren(children[i].categorias)
          jsonTemplate.selected = 1

          result[children[i].id] = jsonTemplate

        }else{

          jsonTemplate = JSON.parse(JSON.stringify(children[i]))
          jsonTemplate.selected = 1

          result[children[i].id] = jsonTemplate
        }
        
        this.$emit('append-category-leaf', jsonTemplate, true);
      
      }
      return result
    },
    parentOnClick(iParent){
      if(this.items[iParent].selected==Object.keys(this.items[iParent].categorias).length){
        Object.values(this.items[iParent].categorias).forEach(cat => {
              this.childOnClick(iParent,cat.id,false)
        })
        this.items[iParent].selected=0
      }else{
        Object.values(this.items[iParent].categorias).forEach(cat => {
              this.childOnClick(iParent,cat.id,true)
        })
        this.items[iParent].selected=Object.keys(this.items[iParent].categorias).length
      }
    },
    childOnClick(iParent,iChild,forceStatus=null){
      if(forceStatus==null){
        if(this.items[iParent].categorias[iChild].selected==0){
          this.items[iParent].categorias[iChild].selected = 1
          this.items[iParent].selected++
          this.$emit('input', iParent, iChild,true);
        }else{
          this.items[iParent].categorias[iChild].selected=0
          this.items[iParent].selected--
          this.$emit('input', iParent, iChild,false);
        }
      }else if(forceStatus==true){
        this.items[iParent].categorias[iChild].selected = 1
        this.items[iParent].selected++
        this.$emit('input', iParent, iChild,true);
      }else{
        this.items[iParent].categorias[iChild].selected=0
        this.items[iParent].selected--
        this.$emit('input', iParent, iChild,false);
      }
    },
  },
  data () {
    return {
      _categoriesSrv: null,
      loading:true,
      initialLoad:true,
      show:!this.isMobile,
      icons:{
        alimentacion: 'mdi-silverware-fork',
        aprender: 'mdi-book-open-variant',
        hogares: 'mdi-home',
        pcomunitarias: 'mdi-palette',
        salud: 'mdi-hospital',
        vestirse: 'mdi-hanger'
      },
      error:null,
      items: {},
      menu: [
          { title: 'Sumá tu ORG', icon: 'mdi-plus-circle', to:'/sumate'},
          { title: 'Contactanos', icon: 'mdi-email-edit', to:'/contacto'},
          { title: 'Acerca del Mapa', icon: 'mdi-information', to:'/acerca-de'},
        ]
    };
  }
}
</script>
