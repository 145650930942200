<template>
  <v-app>
    <AppBar :isMobile="isMobile"/>

    <v-main>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-card
              class="mx-auto"
              outlined
              rounded="lg"
              id="acerca-de"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Acerca del #MapaSolidario</v-list-item-title>

                  <v-card-text class="text--primary">
                    <br><div>El mapa surgió a partir de nuestro trabajo en <a href="https://www.noseaspavote.org.ar" target="_blank">No Seas Pavote</a> con la gente en situación de calle y como respuesta a la necesidad de saber e informar aquellas instituciones que prestan servicios gratuitos orientadas a esta realidad.</div>

                    <br><div>Se fijaron 3 condiciones para su realización: que fuese del partido de Lomas de Zamora, gratuito y de respuesta inmediata (mapa Impreso). Se relevaron más de 180 instituciones.</div>

                    <br><div>El Mapa Solidario no tiene afiliación ni recurso político ni lucro alguno; es totalmente gratuito y su fin es socializar la información de todas las organizaciones en él plasmadas, para formar una Red de trabajo en conjunto. Todas las instituciones intervinientes manifestaron su consentimiento de participación.</div>
                  </v-card-text>

                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="80"
                ><v-img :src="require('@/assets/manos.png')"></v-img></v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn text link href="https://cambalachecoopera.com.ar/mapa-solidario-noseaspavote/" target="_blank">Conocer más</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-for="item in social" :key="item.icon" text link :href="item.href" target="_blank"><v-icon size="24px">{{ item.icon }}</v-icon></v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-card
              class="mx-auto"
              outlined
              rounded="lg"
              id="contacto"
              :loading="formLoading"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Contacto</v-list-item-title>
                  <v-alert
                    :value="formAlert"
                    transition="scale-transition"
                    :type="formAlertType"
                    dismissible
                  >
                    {{this.formAlertText}}
                  </v-alert>
                  <v-form
                    ref="form"
                    v-model="formValid"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="formName"
                      :counter="20"
                      :rules="formNameRules"
                      label="Nombre"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="formEmail"
                      :rules="formEmailRules"
                      label="E-mail"
                      required
                    ></v-text-field>

                    <v-textarea
                      v-model="formMessage"
                      label="Mensaje"
                      :rules="formMessageRules"
                      :counter="200"
                      required
                    ></v-textarea>

                    <v-row class="mt-3" align="center" justify="center">
                        <vue-hcaptcha
                          ref="hCaptcha" 
                          :sitekey="getHcaptchaSitekey()" 
                          language="es"
                          @verify="onHcaptchaVerify"
                          @expired="onHcaptchaExpire"
                          @challengeExpired="onHcaptchaChallengeExpire"
                          @error="onHcaptchaError"
                        />
                    </v-row>    
                    <v-row v-if="hCaptchaShowValidationMessage" class="mt-3" align="center" justify="center">
                      <div style="color:red;font-size:12px">Por favor completar para continuar.</div> 
                    </v-row>                
                  </v-form>

                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  @click="reset"
                >
                  Borrar
                </v-btn>

                <v-btn
                  @click="onSubmit"
                >
                  Enviar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-sheet
              rounded="lg"
              dark
              color="#064A60"
              class="pa-0 text-center"
            >
                <v-row align="center" no-gutters>
                  <v-col
                    cols="3"
                    sm="4"
                  >
                    <a href="https://www.cambalachecoopera.com.ar" target="_blank"><v-img contain max-height="100" :src="require('@/assets/cambalache_logo.png')"></v-img></a>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="4"
                  >
                    un proyecto de
                  </v-col>
                  <v-col
                    cols="3"
                    sm="4"
                  >
                    <a href="https://www.noseaspavote.org.ar" target="_blank"><v-img contain max-height="100" :src="require('@/assets/nsp_logo.png')"></v-img></v-img></a>
                  </v-col>
                </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import AppBar from './partials/AppBar.vue';
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
  import formsService from '../services/forms.service.js';

  export default {
    props: {
      isMobile: Boolean,
    },
    components: {
      AppBar,
      VueHcaptcha 
    },
    mounted() {
      this.onPageLoad()
    },
    methods: {
      reset () {
        this.$refs.form.reset()
      },
      onHcaptchaVerify(token, ekey) {
          this.hCaptchaVerified = true;
          this.hCaptchaToken = token;
          this.hCaptchaEKey = ekey;
          this.hCaptchaShowValidationMessage = false;
          console.log(`Callback token: ${token}, ekey: ${ekey}`);
      },
      onHcaptchaExpire() {
          this.hCaptchaVerified = false;
          this.hCaptchaToken = null;
          this.hCaptchaEKey = null;
          this.hCaptchaExpired = true;
          console.log('Expired');
      },
      onHcaptchaChallengeExpire() {
          this.hCaptchaVerified = false;
          this.hCaptchaToken = null;
          this.hCaptchaEKey = null;
          this.hCaptchaExpired = true;
          console.log(`Challenge expired`);
      },
      onHcaptchaError(err) {
          this.hCaptchaToken = null;
          this.hCaptchaEKey = null;
          this.hCaptchaError = err;
          console.log(`Error: ${err}`);
      },
      getHcaptchaSitekey() {
        return process.env.VUE_APP_HCAPTCHA_SITE_KEY
      },
      onSubmit() {
          this.formAlert = false;
          this.formLoading = true;

          let fieldsValidation = this.$refs.form.validate()

          //verify hCaptcha
          if(!this.hCaptchaVerified)
          {
            this.hCaptchaShowValidationMessage = true;
            this.formLoading = false;
            return
          }

          //validate form, if true then send form!
          if(fieldsValidation){
            const data = 'email='+encodeURIComponent(this.formEmail)+'&name='+encodeURIComponent(this.formName)+'&message='+encodeURIComponent(this.formMessage)+'&hcaptcha-response='+this.hCaptchaToken

            this.formService.postContactForm(data).then(response => {
                this.onFormResponse(response)
            }).catch((e) => {
                this.onFormError(e)
            })
          }else{
            this.formLoading = false;
            return
          }
      },
      onFormResponse(response){
        console.log("form-response",response)
        this.formLoading = false;
        this.formAlertText = 'Gracias por ponerse en contacto con el equipo del #MapaSolidario. Te contestaremos a la brevedad.';
        this.formAlertType = 'success';
        this.formAlert = true;
        this.reset();
      },
      onFormError(e){
        console.log("form-error",e)

        
        if(e.response.status=429){
          this.formAlertText = "No se pueden enviar multiples formularios en tan poco tiempo, vuelva a intentar más tarde."
        }else if(e.response.status=400){
          this.formAlertText = "Se a producido un error, vuelva a intentar en otro momento"
        }else{
          this.formAlertText = "No se ha podido determinar la causa del error, vuelva a intentar en otro momento"
        }

        this.formAlertType = 'error';
        this.formAlert = true;
        this.formLoading = false;
      },
      onPageLoad(){
        if(this.$route.hash) this.scrollTo(this.$route.hash)
      },
      scrollTo(elementId){
        const element = document.getElementById(elementId);
        if(element) element.scrollIntoView();
      }
    },
    data: () => ({
      social: [
        {
          icon: 'mdi-instagram',
          href: 'https://instagram.com/cambalachecoopera/',
          tooltip: "Instagram del desarrollador."
        },
        {
          icon: 'mdi-twitter',
          href: 'https://twitter.com/cambalachecoope',
          tooltip: "Twitter del desarrollador."
        }
      ],
      hCaptchaSitekey: '',
      hCaptchaShowValidationMessage: false,
      hCaptchaVerified: false,
      hCaptchaExpired: false,
      hCaptchaToken: null,
      hCaptchaEKey: null,
      hCaptchaError: null,
      formAlert:false,
      formAlertType: 'success',
      formAlertText: '',
      formLoading: false,
      formService: new formsService(),
      formValid: false,
      formName: '',
      formMessage: '',
      formMessageRules: [
        v => !!v || 'Por favor incluya un mensaje',
        v => (v && v.length <= 200) || 'El mensaje no puede superar los 200 caracteres',
      ],
      formNameRules: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 20) || 'El nombre debe ser de hasta 20 caracteres',
      ],
      formEmail: '',
      formEmailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail no válido',
      ]
    }),
  }
</script>