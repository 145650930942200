<template>
  <v-app>
    <AppBar :isMobile="isMobile"/>
    <v-main>
      <v-container fluid>
        <iframe class="fill-heigh" src="https://docs.google.com/forms/d/e/1FAIpQLSdgrK_hRyZL0vxkV8WGIHpnT78-WZOiDBaaahzFjzgKA6u_gw/viewform?embedded=true" width="100%" height="700" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import AppBar from './partials/AppBar.vue';

  export default {
    props: {
      isMobile: Boolean,
    },
    components: {
      AppBar
    },
    data: () => ({
      social: [
        {
          icon: 'mdi-instagram',
          href: 'https://instagram.com/cambalachecoopera/',
          tooltip: "Instagram del desarrollador."
        },
        {
          icon: 'mdi-twitter',
          href: 'https://twitter.com/cambalachecoope',
          tooltip: "Twitter del desarrollador."
        }
      ],
    }),
  }
</script>