<template>
  <v-app-bar
    app
    color="#EDEDED"
    flat
    height="52"
  >
        <v-avatar
          size="40"
        >
          <v-img :src="require('@/assets/manos.png')"></v-img>
        </v-avatar>
      <v-app-bar-title>
        <v-btn
        depressed
        tile
        color="#EDEDED"
        x-large
        class='text-h5 mapasoli-title-color'
      >MAPA <b>SOLIDARIO</b></v-btn></v-app-bar-title>

      <v-spacer></v-spacer>
      <template 
        v-if="!isMobile">
      <v-btn
        depressed
        tile
        v-for="link in menu"
        color="#EDEDED"
        :key="link.title"
        link
        :to="link.to"
      >
        {{ link.title }}
      </v-btn>
      </template>
      <v-menu class="hidden-md-and-up" v-else>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="link in menu"
            :key="link.title"
            :to="link.to"
          >
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
  </v-app-bar>
</template>

<script>
  export default {
    props: {
      isMobile: Boolean
    },
    data: () => ({
      menu: [
          { title: 'Sumate', to:'/sumate'},
          { title: 'Acerca del Mapa', to:'/acerca-de'},
          { title: 'ir al mapa', to:'/'},
      ]
    }),
  }
</script>