export default class {
    
    data = {}

    constructor(options = {}) {
    }

    getName(route){
        return route.name
    }

    getAction(route){
        if(route.params.action) return route.params.action.toLowerCase()
        return null
    }

    getUrlData(route){

        this.data = {}
        this.urlData = {}
        
        if(route.params.action) this.urlData['action'] = route.params.action.toLowerCase()
        if(route.params.query) this.urlData['query'] = route.params.data
        
        
        if(route.params.data){
            this.urlData.data = this.splitDataParam(route.params.data)
        }
        
        if(route.params.centroid){
            this.urlData.centroid = route.params.centroid
        }

        return this.urlData
    }

    urlParamsBuilder(urlData){

        if(urlData.data){
            urlData.data = 'd='+Object.keys(urlData.data).map((key) => [key, urlData.data[key]].join('::')).join('!!')
        }
        return urlData
    }

    splitDataParam(dataVal){
        let dataMatch = dataVal.replace('d=','')
        let dataResult = {}

        if(dataMatch.length==0) return dataResult

        let dataSplit = dataMatch.split('!!')

        dataSplit.forEach(element => {
            let aux = element.split('::')
            dataResult[aux[0]] = aux[1]
        });
        
        return dataResult
    }

    stringifyCentroid(centroid=null,zoom=null,includeDelimiter=true){  
        
        let result = centroid.lat+','+centroid.lng+','+zoom
  
        if(includeDelimiter) result = '@'+result+'z'
  
        return result
    }

}