<template>
  <v-container fill-height fluid>
    <v-content >
      <v-row class="text-center">
        <v-col class="mb-4">
        <v-img
          transition="slide-y-transition"
          :src="require('../assets/manos.png')"
          class="my-3"
          contain
          height="200"
        />
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Sitio en mantenimiento
          </h1>

          <p class="subheading font-weight-regular">
            Estamos realizando mantenimiento a nuestro sitio. Mientras tanto te invitamos a seguirnos en nuestras redes!
          </p>
        </v-col>
      </v-row>
      <v-row class="text-center mb-10">
        <v-col class="mb-4">
          <v-icon>mdi-email-outline</v-icon> contacto@cambalachecoopera.com.ar
        </v-col>
      </v-row>
      <v-row class="no-gutters text-center mt-10">
          <v-spacer></v-spacer>
          <v-col cols="1" 
            v-for="(link, i) in links"
            :key="i"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  :href="link.href"
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    {{ link.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ link.tooltip }}</span>
            </v-tooltip>
          </v-col>
          <v-spacer></v-spacer>
      </v-row>
    </v-content>
  </v-container>
</template>

<script>
  export default {
    name: 'SiteOffline',
    metaInfo: {
      title: 'En Mantenimiento',
    },
    data: () => ({
      links: [
        {
          icon: 'mdi-instagram',
          href: 'https://instagram.com/cambalachecoopera/',
          tooltip: "Instagram del desarrollador."
        },
        {
          icon: 'mdi-twitter',
          href: 'https://twitter.com/cambalachecoope',
          tooltip: "Twitter del desarrollador."
        },
        {
          icon: 'mdi-application',
          href: 'https://cambalachecoopera.com.ar/',
          tooltip: "Sitio web del desarrollador."
        },
      ],
    }),
  }
</script>

<style>
.login-form{
  max-width: 500px
}
</style>
