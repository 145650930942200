<template>
  <div>
    <v-bottom-sheet v-model="show" scrollable v-if="layout==='bottom'" hide-overlay persistent>
      <v-card width="100vv">
          <v-card-text style="height: 50vh;" class="px-0">
            <div class="text-center" style="width: 100%;">
              <v-btn
                icon
                x-small
                @click.stop="show = !show"
                :loading="loading"
              >
                <v-icon>{{ !show?'mdi-menu-up':'mdi-menu-down'}}</v-icon>
              </v-btn>
            </div>
            <v-img
              height="200px"
              :src="require('@/assets/pexels-bruno-salvadori-5076388.jpg')"
              v-if="institucion"
            >
              
            </v-img>
            <v-divider></v-divider>
            
            <v-card
              class="mx-auto"
              elevation="0"
              v-if="institucion"
            >
              <v-card-title style="word-break: normal;"><span class="text-h6">{{this.institucion.nombre}}</span></v-card-title>
              <v-card-subtitle>{{this.institucion.tipo}}</v-card-subtitle>

              <v-card-text>
                <div>
                  {{this.institucion.descripcion.trim().length==0?'No se incluyó una descripción.':this.institucion.descripcion}}
                </div>
              </v-card-text>

              <v-divider></v-divider>
              <v-row
                no-gutters
                class="py-2"
              > 
                <v-spacer></v-spacer>
                <v-col
                  cols="3"
                >
                  <v-card flat :ripple="false" link class='text-center' @click.stop="$emit('map:center',institucion.ubicacion,20)">
                      <v-btn
                        fab
                        x-small
                        color="primary"
                        outlined
                        plain
                      >
                        <v-icon dark>
                          mdi-map-marker-radius
                        </v-icon>
                    </v-btn>
                    <div class="text-caption primary--text" style="word-break: normal;">Centrar</div>
                  </v-card>
                </v-col>
                <v-col
                  cols="3"
                >
                  <v-card flat :ripple="false" link class='text-center' @click.stop="shareViaWebShare()">
                      <v-btn
                        fab
                        x-small
                        color="primary"
                        outlined
                        plain
                      >
                        <v-icon dark>
                          mdi-share-variant
                        </v-icon>
                    </v-btn>
                    <div class="text-caption primary--text" style="word-break: normal;">Compartir</div>
                  </v-card>
                </v-col>
              </v-row>
            <v-divider></v-divider>
              
              <v-list>
                <template v-for="(data,i) in this.contacto" >
                  <v-list-item :key="i" link :target="data.target" :href="data.href">
                    <v-list-item-icon>
                      <v-icon color="indigo">
                        {{data.icon}}
                      </v-icon>
                    </v-list-item-icon>
                    
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">{{data.display}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider inset :key="'list-divider'+i" v-if="i!=contacto.length-1"></v-divider>
                </template>
              </v-list>

              <v-divider class="mx-1"></v-divider>
              <v-row class="mx-2 my-1" v-if="categorias">
                <v-chip  class="ma-1" link v-for="c in institucion.categorias" dark :key="c.id" :color="categorias[c.id]?categorias[c.id].data.clase.color:'gray'">{{c.nombre}}</v-chip>
              </v-row>
              <div class="text-center mt-3" style="width: 100%;">
                <v-btn text color="red" x-small @click.stop="reportIncident">
                  <v-icon light x-small>mdi-alert</v-icon><small>Reportar un error</small>
                </v-btn>
              </div>
            </v-card>
            <v-card
                class="mx-auto mt-2 py-4"
                outlined
                v-if="error"
              >
              <v-card-title>
                <v-icon color="red">mdi-close-octagon</v-icon> Se ha producido un error. 
              </v-card-title>

              <v-card-subtitle>No se ha podido cargar la información de la institución.
                Por favor, intentá recargando la página.</v-card-subtitle>

              <v-card-actions>
                <v-btn text @click.stop="reloadPage">
                  recargar
                </v-btn>
              </v-card-actions>
            </v-card>
            <ReportIncident ref="reportIncident" @show-snackbar="showSnackbar"/>
          </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-navigation-drawer
        v-model="show"
        absolute
        width="380"
        ref="resultDrawer"
        style="z-index: 10;"
        :permanent="show"
        bottom
        hide-overlay
        v-if="layout==='drawer'"
      >
        <v-img
          height="200px"
          :src="require('@/assets/pexels-bruno-salvadori-5076388.jpg')"
          v-if="institucion"
        >
          <v-list dense>
              <v-list-item class="my-0" >
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  small
                  @click.stop="show = !show"
                  :loading="loading"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item>
          </v-list>
        </v-img>
        <v-list dense v-else>
            <v-list-item class="my-0" >
              <v-spacer></v-spacer>
              <v-btn
                fab
                small
                @click.stop="show = !show"
                :loading="loading"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item>
        </v-list>
        <v-card
          class="mx-auto"
          elevation="0"
          v-if="institucion"
        >
          <v-card-title style="word-break: normal;"><span class="text-h6">{{this.institucion.nombre}}</span></v-card-title>
          <v-card-subtitle>{{this.institucion.tipo}}</v-card-subtitle>

          <v-card-text>
            <div>
              {{this.institucion.descripcion.trim().length==0?'No se incluyó una descripción.':this.institucion.descripcion}}
            </div>
          </v-card-text>

          <v-divider></v-divider>
            <v-row
              no-gutters
              class="py-2"
            > 
              <v-spacer></v-spacer>
              <v-col
                cols="3"
              >
                <v-card flat :ripple="false" link class='text-center' @click.stop="$emit('map:center',institucion.ubicacion,20)">
                    <v-btn
                      fab
                      x-small
                      color="primary"
                      outlined
                      plain
                    >
                      <v-icon dark>
                        mdi-map-marker-radius
                      </v-icon>
                  </v-btn>
                  <div class="text-caption primary--text" style="word-break: normal;">Centrar</div>
                </v-card>
              </v-col>
              <v-col
                cols="3"
              >
                <v-card flat :ripple="false" link class='text-center' @click.stop="shareViaWebShare()">
                    <v-btn
                      fab
                      x-small
                      color="primary"
                      outlined
                      plain
                    >
                      <v-icon dark>
                        mdi-share-variant
                      </v-icon>
                  </v-btn>
                  <div class="text-caption primary--text" style="word-break: normal;">Compartir</div>
                </v-card>
              </v-col>
            </v-row>
          <v-divider></v-divider>
          
          <v-list>
            <template v-for="(data,i) in this.contacto" >
              <v-list-item :key="i" link :target="data.target" :href="data.href">
                <v-list-item-icon>
                  <v-icon color="indigo">
                    {{data.icon}}
                  </v-icon>
                </v-list-item-icon>
                
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{data.display}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset :key="'list-divider'+i" v-if="i!=contacto.length-1"></v-divider>
            </template>
          </v-list>

          <v-divider class="mx-1"></v-divider>
          <v-row class="mx-2 my-1">
            <v-chip  class="ma-1" link v-for="c in institucion.categorias" dark :key="c.id" :color="categorias[c.id].data.clase.color">{{c.nombre}}</v-chip>
          </v-row>
          <div class="text-center mt-3" style="width: 100%;">
            <v-btn text color="red" x-small @click.stop="reportIncident">
              <v-icon light x-small>mdi-alert</v-icon><small>Reportar un error</small>
            </v-btn>
          </div>
        </v-card>
        <v-card
            class="mx-auto mt-2 py-4"
            outlined
            v-if="error"
          >
          <v-card-title>
            <v-icon color="red">mdi-close-octagon</v-icon> Se ha producido un error. 
          </v-card-title>

          <v-card-subtitle>No se ha podido cargar la información de la institución.
            Por favor, intentá recargando la página.</v-card-subtitle>

          <v-card-actions>
            <v-btn text @click.stop="reloadPage">
              recargar
            </v-btn>
          </v-card-actions>
        </v-card>
        <ReportIncident ref="reportIncident" @show-snackbar="showSnackbar"/>
    </v-navigation-drawer>
  </div>
</template>

<script>
import institutionsService from '../../services/institutions.service.js';
import ReportIncident from './ReportIncident.vue';

export default {
  components: {
    ReportIncident
  },
  props: {
    isMobile: Boolean,
    layout: {
      type: String,
      default: 'drawer'
    }
  },
  mounted() {
    
  },
  computed: {
    //
  },
  watch: {
      institucion(newVal) {

        this.contacto = []

        if(newVal==null) return

        if(newVal.contacto){
          Object.keys(newVal.contacto).forEach(iContacto => {
            switch (iContacto) {
              case 'email':
                Object.values(newVal.contacto.email).forEach(vMail => {
                  this.contacto.push({
                    icon: this.icons.email,
                    display: vMail.valor,
                    href: 'mailto:'+vMail.valor,
                    target: '_blank'
                  });
                })
                break;
            
              case 'telefonos':
                Object.values(newVal.contacto.telefonos).forEach(vTel => {
                  this.contacto.push({
                    icon: this.icons.telefonos,
                    display: vTel.valor,
                    href: 'tel:'+vTel.valor,
                    target: ''
                  });
                })
                break;
            
              case 'social':
                Object.keys(newVal.contacto.social).forEach(iSocial => {
                  this.contacto.push({
                    icon: this.icons[iSocial],
                    display: newVal.contacto.social[iSocial],
                    href: newVal.contacto.social[iSocial],
                    target: '_blank'
                  });
                })
                break;
            
            
              case 'sitio_web':
                this.contacto.push({
                  icon: this.icons.sitio_web,
                  display: "Sitio web oficial",
                  href: newVal.contacto.sitio_web,
                    target: '_blank'
                });
                break;
            
              default:
                break;
            }
          })
        }

        if(newVal.direccion){
          this.contacto.push({
              icon: this.icons.direccion,
              display: newVal.direccion.calle_y_numero + ', ' + newVal.direccion.localidad,
              href: '#',
              target: ''
            });
        }
      
      }
  },
  methods: {
    open(){
      this.show = true
    },
    isOpen(){
      return this.show
    },
    reportIncident(){
      this.$refs.reportIncident.formDetails = this.institucion
      this.$refs.reportIncident.dialog = true
    },
    reloadPage(){
      window.location.reload()
    },
    getinstitution(){
      return this.institucion
    },
    showInstitution(instId,categories=null){

      if(categories) this.categorias=categories

      this.institucion = null
      
      //si ya tengo cargada la institucion, no la vuelvo a solicitar
      if(this.institucionesJson!=null && this.institucionesJson[instId]) {
        
        this.institucion = this.institucionesJson[instId]

        return
      }

      this.loading = true
      
      this.institucion = null

      this.services.institutions.getById(instId, 'categorias').then(response => {
        
        //TODO use a log utility
        //console.log("ResultComponent:Institution:getById", response)

        this.institucionesJson[instId] = response.data.value[0]
        
        this.institucion = response.data.value[0]
        
        this.loading = false
      }).catch((e) => {
          console.error("Institutions service fail:",e)
          this.error=true
          this.loading = false
      })

    },
    showSnackbar(message,color){
      this.$emit('show-snackbar',message,color)
    },
    async shareViaWebShare() {
      let shareData = {
          title: '#MapaSolidario',
          text: 'El Mapa solidario',
          url: 'https://mapasolidario.org.ar'
        }
      try {
        let sharePromise = await navigator.share(shareData)
        //alert("compartidisimo")
      } catch(err) {
        //alert(err.message)
      }

      
      
    }
  },
  data () {
    return {
      show:false,
      loading:true,
      services:{
        institutions: new institutionsService(),
      },
      icons: {
        instagram: 'mdi-instagram',
        facebook: 'mdi-facebook',
        twitter: 'mdi-twitter',
        sitio_web: 'mdi-application',
        telefonos: 'mdi-phone',
        email: 'mdi-email-outline',
        direccion: 'mdi-map-marker'
      },
      institucion: null,
      contacto:[],
      institucionesJson:[],
      categorias: {},
      error:false
    };
  }
}
</script>
