<template>
  <router-view ref="main" :isMobile="isMobile" :navDrawerWidth="navDrawerWidth" @reload-app="reload" @force-upgrade="forceSWupdate"></router-view>
</template>
 
<script>
  export default {
    mounted() {
      this.registerSW()
    },
    computed:{
      isMobile () {
        //por ahora siempre deuvelve que no
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true //iphone plus
          case 'sm': return true //ipad
          case 'md': return true //ipad pro
          case 'lg': return false
          case 'xl': return false
          default: return true
        }
      
      },
      navDrawerWidth () {
        //por ahora siempre deuvelve que no
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 260 //iphone plus
          case 'sm': return 380 //ipad
          case 'md': return 380 //ipad pro
          case 'lg': return 380
          case 'xl': return 380
          default: return 380
        }
      
      }
    },
    methods: {
      forceSWupdate () {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (let registration of registrations) {
              // console.log(registration)
              registration.update()
            }
          })
        }
      },
      registerSW(){
        if ('serviceWorker' in navigator) {
          
            navigator.serviceWorker.register('/service-worker.js').then(reg => {
              console.log("serviceWorker",this.newWorker)
              reg.addEventListener('updatefound', () => {
                // A wild service worker has appeared in reg.installing!
                this.newWorker = reg.installing;
                
                console.log("updatefound",this.newWorker)
                this.newWorker.addEventListener('statechange', () => {
                  // Has network.state changed?
                  switch (this.newWorker.state) {
                    case 'installed':
                      if (navigator.serviceWorker.controller) {
                        // new update available
                        console.log("Available",this.newWorker)
                        this.$refs.main.snackbarNewVersionAlert=true
                      }
                      // No update available
                      break;
                  }
                });
              });
            });
        
        let refreshing;
        navigator.serviceWorker.addEventListener('controllerchange', function () {
          if (refreshing) return;
          window.location.reload();
          refreshing = true;
        });
        
        }
      },
      reload(){
        this.newWorker.postMessage({ action: 'skipWaiting' });
      }
    },
    data: () => ({
      mini: null,
      drawer: false,
      newWorker: Object
    }),
  }
</script>