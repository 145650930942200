import axios from 'axios';

export default class {
    constructor (options = {}) {
        this.endpoint = 'instituciones'   
    }

    get(centerLat,centerLong, extend=null){
        
        let uri = process.env.VUE_APP_API_BASE_URI + this.endpoint

        uri = uri + '?%24centroLat='+centerLat+'&%24centroLong='+centerLong
        
        if(extend!=null){
            uri = uri + '&%24extend=' + extend
        }
        
        return axios({
            url: uri,
            method: 'get',
            timeout: this.timeout
        })
    }
    
    getById(id, extend=null){
        let uri = process.env.VUE_APP_API_BASE_URI + this.endpoint + '/' + id

        if(extend!=null){
            uri = uri + '?%24extend=' + extend
        }

        return axios({
            url: uri,
            method: 'get',
            timeout: this.timeout
        })
    }
}