<template>
  <l-map 
    ref="mapComponent" 
    :zoom="zoom" 
    :center="center" 
    :options="mapOpt"
    @update:bounds="onUpdateBounds"
    style="z-index: 0;">

    <l-control position="topleft" v-if="!mobile">
      <v-btn 
      fab
      small
      color="#EDEDED"
      style="color:#064A60"
      @click.stop="$emit('toggle-sidebar')"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </l-control>

    <l-control-layers position="topright"></l-control-layers>

    <l-tile-layer 
    v-for="tileProvider in tileProviders"
    :key="tileProvider.name"
    :name="tileProvider.name"
    :visible="tileProvider.visible"
    :url="tileProvider.url"
    :attribution="tileProvider.attribution"
    layer-type="base"/>

    <l-marker :lat-lng="location.coordinates" name="currentLocation" :visible="location.visible">
      <l-icon :icon-size="[20, 20]"
              :icon-anchor="[8, 8]"
              :icon-url="require('@/assets/location_marker.png')">
      </l-icon>
    </l-marker>

    <Minimap ref="minimap" :isMobile="mobile"/>

    <l-control-zoom position="bottomright"/>
    
    <l-control position="bottomright">
      <v-tooltip left z-index="12" :disabled="mobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
          fab
          x-small
          color="white"
          @click="getCurrentLocation"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon color="#1976d2">mdi-crosshairs-gps</v-icon>
          </v-btn>
        </template>
        <span>Ver tu ubicación</span>
      </v-tooltip>
    </l-control>
  </l-map>
</template>

<script>
import L from 'leaflet'
import {LMap, LTileLayer, LGeoJson,LControlZoom,LControl,LMarker,LLayerGroup,LTooltip,LIcon,LControlLayers} from 'vue2-leaflet';
import Minimap from './Minimap.vue';
 
export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LControlZoom,
    LControl,
    LMarker,
    LLayerGroup,
    LTooltip,
    LIcon,
    LControlLayers,
    Minimap
  },
  props: {
    mobile: Boolean,
    zoomControl: {
      type: Boolean,
      default: false
    },
    attributionControl: {
      type: Boolean,
      default: true
    }
  },
  metaInfo: {
    //
  },
  mounted() {
  },
  computed: {
    mapOpt(){
      return {
        zoomControl: this.zoomControl,
        attributionControl: this.attributionControl
      }
    }
  },
  watch: {
    
  },
  methods: {
    getMapObj(){
      return this.$refs.mapComponent.mapObject
    },
    getZoom(){
      return this.$refs.mapComponent.mapObject.getZoom()
    },
    setZoom(z){
      this.zoom = z
    },
    getCenter(){
      return this.$refs.mapComponent.mapObject.getBounds().getCenter()
    },
    setView(latLng,z){
      this.$refs.mapComponent.mapObject.setView(new L.LatLng(latLng.lat,latLng.lng),z)
    },
    stringToCentroid(centroidString){

      if(!centroidString) return null

      let values = centroidString.match('-?\\d*.\\d*,-?\\d*.\\d*,\\d*.\\d*')[0].slice(0, -1).split(',')

      return {
        lat: values[0],
        lng: values[1],
        z: values[2]
      }
    },
    getCentroidToString(latLng=null,includeDelimiter=true){
      let zoomLevel = this.getZoom()

      let result = ''

      if(latLng){
        result = latLng.lat+','+latLng.lng+','+zoomLevel
      }else{
        let centroid = this.getCenter()
        result = centroid.lat+','+centroid.lng+','+zoomLevel
      }

      if(includeDelimiter) result = '@'+result+'z'

      return result
    },
    getCurrentLocation(){

      // https://leafletjs.com/examples/mobile/ --- see more for location goals
      //do we support geolocation
      if(!("geolocation" in navigator)) {
        let err = {
          message: 'CUSTOM: Geolocation is not available.'
        }
        
        this.$emit('error:get-current-location',err)
        return;
      }
      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(pos => {

        this.location.coordinates = [pos.coords.latitude,pos.coords.longitude];
        this.$refs.mapComponent.mapObject.setView([pos.coords.latitude,pos.coords.longitude],14)
        this.location.visible = true;
        
      }, err => {
        this.$emit('error:get-current-location',err)
      })
    },
    onUpdateBounds(bounds){
      this.$emit('update:bounds',bounds)
    }
  },
  data () {
    return {
      name:"leaflet-map",
      showLegend:false,
      zoom: 15,
      center: [-34.7619294,-58.4169586],
      location: {
        coordinates: [0,0],
        visible: false
      },
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> &#124; Desarrollado por <a href="https://cambalachecoopera.com.ar/" target="_blank">Cambalache</a>',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'Argenmap',
          visible: false,
          url: 'https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{-y}.png',
          attribution:
            '<a href="https://www.ign.gob.ar/AreaServicios/Argenmap/IntroduccionV2" target="_blank">Instituto Geográfico Nacional</a> + <a href="https://www.osm.org/copyright" target="_blank">OpenStreetMap</a> &#124; Desarrollado por <a href="https://cambalachecoopera.com.ar/" target="_blank">Cambalache</a>',
        },
        {
          name: 'Satelital',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
            'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        }
      ],
    };
  }
}
</script>

<style>@import "~leaflet/dist/leaflet.css";</style>
<style>@import '~leaflet-minimap/dist/Control.MiniMap.min.css';</style>

