import Vue from 'vue'
import VueRouter from 'vue-router'
import SiteOffline from '../components/SiteOffline.vue'
import MapWrapper from '../components/MapWrapper.vue'
import About from '../components/About.vue'
import Register from '../components/Register.vue'

Vue.use(VueRouter)
let routes
//si estoy en modo offline, entonces cargo las rutas de mantenimiento, caso contrario puedo navegar
if(process.env.VUE_APP_SITE_OFFLINE.toLocaleLowerCase()==='true'){
  routes = [
    {
      path: '/',
      name: 'root',
      component: SiteOffline,
    },
    {
      path: '*',
      redirect: { name: 'root' }
    }
  ]
}else{
  routes = [
    {
      path: '/:action/:query/:centroid(@-?\\d*.\\d*,-?\\d*.\\d*,\\d*.\\d*z)?/:data(d=.*)?',
      name: 'map-action',
      component: MapWrapper,
    },
    {
      path: '/:centroid(@-?\\d*.\\d*,-?\\d*.\\d*,\\d*.\\d*z)?',
      name: 'map-wrapper',
      component: MapWrapper,
    },
    {
      path: '*',
      redirect: { name: 'map-wrapper' }
    },
    {
      path: '/acerca-de',
      name: 'about',
      component: About,
    },
    {
      path: '/sumate',
      name: 'register',
      component: Register,
    },
    {
      path: '/contacto',
      name: 'contact',
      redirect: '/acerca-de'
    },
  ]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
