import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
//import store from './store'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import VueGtag from "vue-gtag";
//import { logger } from './extras/logger';

Vue.config.productionTip = false
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueGtag, {
  config: { id:process.env.VUE_APP_GTAG_ID },
  appName: 'mapasoli-web-cli',
  pageTrackerScreenviewEnabled: true
}, router);

new Vue({
  metaInfo: {
    titleTemplate: (title) => title ? `${title} - Mapa Solidario` : 'Mapa Solidario',
    meta: [
      //Open Graph // Facebook
      {property: 'og:type', content: 'website'},    
      {property: 'og:url', content: 'https://www.mapasolidario.org.ar/'},  
      {property: 'og:title', content: "Mapa Solidario"},  
      {property: 'og:description', content: "El mapa surgió a partir de nuestro trabajo en No Seas Pavote con la gente en situación de calle y como respuesta a la necesidad de saber e informar aquellas instituciones que prestan servicios gratuitos orientadas a esta realidad."},  
      {property: 'og:site_name', content: 'Mapa Solidario'},

      //Site
      {name: 'title', content:  '#MapaSolidario'},
      {name: 'description', content:  'El mapa surgió a partir de nuestro trabajo en No Seas Pavote con la gente en situación de calle y como respuesta a la necesidad de saber e informar aquellas instituciones que prestan servicios gratuitos orientadas a esta realidad.'},
      {name: 'robots', content: 'index,follow'},

      //Twitter
      {property: 'twitter:card', content: 'website'},    
      {property: 'twitter:url', content: 'https://www.mapasolidario.org.ar/'},  
      {property: 'twitter:title', content: "Mapa Solidario"},  
      {property: 'twitter:description', content: "El mapa surgió a partir de nuestro trabajo en No Seas Pavote con la gente en situación de calle y como respuesta a la necesidad de saber e informar aquellas instituciones que prestan servicios gratuitos orientadas a esta realidad."},  
      {property: 'twitter:image', content: 'https://www.mapasolidario.org.ar/img/icons/apple-touch-icon.png'},

    ]
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
