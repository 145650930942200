<template>
  <vue-leaflet-minimap
    :layer="minimap.layer"
    :options="minimap.options"
    v-if="!isMobile"
  ></vue-leaflet-minimap>
</template>

<script>
import L from 'leaflet'
import VueLeafletMinimap from 'vue-leaflet-minimap'
 
export default {
  components: {
    VueLeafletMinimap
  },
  props: {
    isMobile: Boolean
  },
  metaInfo: {
    //
  },
  mounted() {
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
  },
  data () {
    return {
      minimap:{
        //about satellite: http://bl.ocks.org/nitaku/047a77e256de17f25e72
        layer: new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
        options: {
          position: 'bottomleft',
          width: 100,
          height: 100,
          collapsedWidth:15,
          collapsedHeight:15,
          toggleDisplay: true,
          zoomLevelOffset: -5,
          minimized: false
        }
      }
    };
  }
}
</script>

<style>@import "~leaflet/dist/leaflet.css";</style>
<style>@import '~leaflet-minimap/dist/Control.MiniMap.min.css';</style>

