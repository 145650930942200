<template>
    <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      
    >
      <v-card :loading="formLoading">
        <v-card-title>
          <span class="text-h5">Reportar un problema</span>
        </v-card-title>
        <v-card-text>
          El equipo de #Mapasolidario lo analizará y si desesas que te comunicemos una vez que esté solucionado, por favor dejanos tu email.
          <v-container>
              <v-form
                ref="form"
                v-model="formValid"
                lazy-validation
              >
                <v-text-field
                  v-model="formName"
                  :counter="20"
                  :rules="formNameRules"
                  label="Nombre (opcional)"
                ></v-text-field>

                <v-text-field
                  v-model="formEmail"
                  :rules="formEmailRules"
                  label="E-mail (opcional)"
                ></v-text-field>

                <v-textarea
                  v-model="formMessage"
                  label="Mensaje (requerido)"
                  :rules="formMessageRules"
                  :counter="200"
                  required
                ></v-textarea>

                <v-row class="mt-3" align="center" justify="center">
                    <vue-hcaptcha
                      ref="hCaptcha" 
                      :sitekey="getHcaptchaSitekey()" 
                      language="es"
                      @verify="onHcaptchaVerify"
                      @expired="onHcaptchaExpire"
                      @challengeExpired="onHcaptchaChallengeExpire"
                      @error="onHcaptchaError"
                    />
                </v-row>    
                <v-row v-if="hCaptchaShowValidationMessage" class="mt-3" align="center" justify="center">
                  <div style="color:red;font-size:12px">Por favor completar para continuar.</div> 
                </v-row>                
              </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            @click="dialog = false"
          >
            Salir sin enviar
          </v-btn>
          <v-btn
            dark
            @click="onSubmit"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import formsService from '../../services/forms.service.js';
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

  export default {
    components: {
      VueHcaptcha
    },
    props: {
    },
    mounted() {
      
    },
    computed: {
      //
    },
    methods: {
      onHcaptchaVerify(token, ekey) {
            this.hCaptchaVerified = true;
            this.hCaptchaToken = token;
            this.hCaptchaEKey = ekey;
            this.hCaptchaShowValidationMessage = false;
            console.log(`Callback token: ${token}, ekey: ${ekey}`);
        },
        onHcaptchaExpire() {
            this.hCaptchaVerified = false;
            this.hCaptchaToken = null;
            this.hCaptchaEKey = null;
            this.hCaptchaExpired = true;
            console.log('Expired');
        },
        onHcaptchaChallengeExpire() {
            this.hCaptchaVerified = false;
            this.hCaptchaToken = null;
            this.hCaptchaEKey = null;
            this.hCaptchaExpired = true;
            console.log(`Challenge expired`);
        },
        onHcaptchaError(err) {
            this.hCaptchaToken = null;
            this.hCaptchaEKey = null;
            this.hCaptchaError = err;
            console.log(`Error: ${err}`);
        },
        getHcaptchaSitekey() {
          return process.env.VUE_APP_HCAPTCHA_SITE_KEY
        },
        onSubmit() {
            this.formAlert = false;
            this.formLoading = true;

            let fieldsValidation = this.$refs.form.validate()

            //verify hCaptcha
            if(!this.hCaptchaVerified)
            {
              this.hCaptchaShowValidationMessage = true;
              this.formLoading = false;
              return
            }

            //validate form, if true then send form!
            if(fieldsValidation){
              const data = 'email='+encodeURIComponent(this.formEmail)+'&name='+encodeURIComponent(this.formName)+'&details='+encodeURIComponent(JSON.stringify(this.formDetails))+'&message='+encodeURIComponent(this.formMessage)+'&hcaptcha-response='+this.hCaptchaToken

              this.formService.postIncident(data).then(response => {
                  this.onFormResponse(response)
              }).catch((e) => {
                  this.onFormError(e)
              })
            }else{
              this.formLoading = false;
              return
            }
        },
        onFormResponse(response){
          console.log("form-response",response)
          this.dialog=false
          this.formLoading = false;
          this.$emit('show-snackbar','El error ha sido reportado con éxito. Será revisado al a brevedad.','success')
        },
        onFormError(e){
          console.log("form-error",e)
          this.dialog=false
          this.formLoading = false;
          this.$emit('show-snackbar',"No se ha podido reportar el error, vuelva a intentar más tarde.",'error')
        },
    },
    data () {
      return {
        dialog: false,
        hCaptchaSitekey: '',
        hCaptchaShowValidationMessage: false,
        hCaptchaVerified: false,
        hCaptchaExpired: false,
        hCaptchaToken: null,
        hCaptchaEKey: null,
        hCaptchaError: null,
        formAlert:false,
        formAlertType: 'success',
        formAlertText: '',
        formLoading: false,
        formService: new formsService(),
        formValid: false,
        formDetails: '',
        formName: '',
        formMessage: '',
        formMessageRules: [
          v => !!v || 'Por favor incluya un mensaje',
          v => (v && v.length <= 200) || 'El mensaje no puede superar los 200 caracteres',
        ],
        formNameRules: [
          v => (!v || v.length <= 20) || 'El nombre debe ser de hasta 20 caracteres',
        ],
        formEmail: '',
        formEmailRules: [
          v => (!v || /.+@.+\..+/.test(v)) || 'E-mail no válido',
        ]
      };
    }
  }
</script>
